import { FC, useEffect, useRef } from 'react';
import { Dialog } from './index';

const DisabledMetaMask: FC = ( { onCloseModal, setModalOpen, modalOpen } : any ) => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <Dialog onCloseModal={onCloseModal} setModalOpen={setModalOpen} modalOpen={modalOpen}>
      <>
      <div ref={ref} className="dialog__content">
        <p>You need to install MetaMask</p>
      </div>
      </>
    </Dialog>
  );
};

export default DisabledMetaMask;
