require("dotenv").config();
import { ethers } from "ethers";
import { checkChain, getChain } from "./chain";
import L1L2 from "./L1L2";

const contracts = new L1L2();

const domain = {
  name: "Yeti",
  version: "1",
  chainId: 0,
  verifyingContract: contracts.addressGameLogic(false)
}

const types = {
  Signer: [
    { name: '_user', type: 'address' },
    { name: 'nonce', type: 'uint256' },
  ]
};

const typeClaim = {
  Rarity: [
    { name: 'userAddress', type: 'address' },
    { name: 'tokenId', type: 'uint256' },
    { name: 'level', type: 'uint256' },
    { name: 'exp', type: 'uint256' },
    { name: 'rarity', type: 'uint256' },
    { name: 'pass', type: 'uint256' },
  ]
}
export async function transactionSignerClaim(address: String | null, pass: number) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //@ts-ignore
    const chainId = await getChain(ethereum);
    domain.chainId = chainId;
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner(0)

    const value = {
      userAddress: address,
      tokenId: 0, 
      level: 0,
      exp: 0,
      rarity: 0,
      pass: pass,
    };
    const sign = await signer._signTypedData(domain, typeClaim, value);
    return sign;
  } catch (e) {
    throw e;
  }
}

export async function transactionSigner(address: String | null, nonce: number) {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    //@ts-ignore
    const chainId = await getChain(ethereum);
    domain.chainId = chainId;
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner(0)

    const value = {
      _user: address,
      nonce: nonce, 
    };
    
    const sign = await signer._signTypedData(domain, types, value);
    return sign;
  } catch (e) {
    throw e;
  }
}
