import { FC } from 'react';

import './index.scss';

interface BackButtonProps {
  onCloseModal: () => void;
  className?: string;
}

const BackButton: FC<BackButtonProps> = ({ onCloseModal, className }) => {
  return (
    <button className="btn-goBack" type="button" onClick={onCloseModal}>
      Go Back
    </button>
  );
};

export { BackButton };
