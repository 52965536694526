export const getChain = async (provider: any): Promise<number> => {
  return provider.request({ method: 'eth_chainId' });
}

export const checkChain = async (provider: any, chainId: string): Promise<boolean> => {
  const currentChainId = await provider.request({ method: 'eth_chainId' });
  return currentChainId == chainId;
};

export const changeChainId = async (provider: any, chainId: string) => {
  try {
    await provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainId }]
    });
    return true;
  } catch (err) {
    //@ts-ignore
    if (err.message.includes("Unrecognized chain ID")) {
      const networkMap = {
        POLYGON_MAINNET: {
          chainId: '0x89',
          chainName: "Matic(Polygon) Mainnet",
          nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
          rpcUrls: ["https://polygon-rpc.com"],
          blockExplorerUrls: ["https://www.polygonscan.com/"],
        }
      }
      await provider.request({
        method: "wallet_addEthereumChain",
        params: [networkMap.POLYGON_MAINNET],
      });
    }
    return false;
  }
};
