import './styles.scss';
import heroTextXs from '../../../assets/images/hero-text-xs.png';
import heroTextMd from '../../../assets/images/hero-text.png';

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="page-container page-container--xl hero__main-title-wrapper">
        <h1 className="hero__main-title text-center">Yeti Town</h1>

        <picture>
          <source srcSet={heroTextXs} media="(max-width: 815px)" />
          <img src={heroTextMd} alt="" className="hero__title-img" />
        </picture>
      </div>
    </section>
  );
};

export default Hero;
