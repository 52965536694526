import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import LoadingSplash from 'app/components/map/splashScreens/LoadingSplash';
import modalMap from '../../../assets/images/modal-map.png';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { NftItem } from 'app/components/nftItem';
import { ContractCallContext, ContractCallResults, Multicall } from 'ethereum-multicall';
import Web3 from 'web3';
import axios from 'axios';
import { handleShowErrorNotification } from 'app/helpers/showError';
import { setTokensInitiated, setUninitiatedTokens } from 'app/redux/reducers/TokensSlice';
import { useProvider } from 'app/hooks/provider';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { getUninitiatedTokens } from 'app/helpers/getUninitiatedTokens';
import { getTokensCount } from 'app/helpers/getTokensCount';
import SwiperCore, { Navigation } from 'swiper';
import { ACTION_IDS } from 'app/contracts/actions';
import LEVELING_UP from 'app/contracts/levels';
import nextArrow from '../../../assets/icons/next-arrow.png';
import prevArrow from '../../../assets/icons/prev-arrow.png';
import { ButtonType } from './types';
import { changeChainId, checkChain } from 'app/contracts/chain';
import L1L2 from '../../../contracts/L1L2';
import './styles.scss';
import { transactionSigner, transactionSignerClaim } from 'app/contracts/signData';
import { set11ButtonSwich } from 'app/redux/reducers/TokensSlice';
import { delay } from 'app/helpers/utils';
import { switchNetwork } from 'app/redux/reducers/AuthSlice';
import { BackButton } from 'app/components/backButton';

SwiperCore.use([Navigation]);

const contracts = new L1L2();
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;
const SEND_SIGNED_API = `${process.env.REACT_APP_SEND_SIGNED_API}`;

interface IMapModal {
  selectedActionId: null | number;
  type: undefined | string;
  setModalOpen: (x: boolean) => void;
  modalOpen: boolean;
  _reMigrateId?: number[];
  updateMigrate: any
}

const MigrateModal: FC<IMapModal> = ({ selectedActionId, type, setModalOpen, modalOpen, _reMigrateId, updateMigrate }) => {
  const [fetching, setFetching] = useState<boolean>(false);
  const provider = useProvider();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const [isMapImageLoaded, setIsMapImageLoaded] = useState(false);
  const [selectedUnstakedTokens, setSelectedUnstakedTokens] = useState<number[]>([]);
  const [reMigrateId, setReMigrateId] = useState<number[] | undefined>(_reMigrateId);
  // const [selectedStakedTokens, setSelectedStakedTokens] = useState<number[]>([]);
  // const [selectedInHospitalToken, setSelectedInHospitalToken] = useState<number | null>(null);

  // const [stakedArray, setStakedArray] = useState<any[]>([]);
  let L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);

  const [userNftsData, setUserNftsData] = useState<any[]>([]);

  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const ignoreTokensInitiating = useAppSelector(
    (state) => state.TokensReducer.ignoreTokensInitiating
  );
  const constantsError = useAppSelector((state) => state.ConstantsReducer.error);
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const getUnMigratedYetis = async () => {
    //@ts-ignore
    const result = await (axios.post(SEND_SIGNED_API + 'unMigrated', { address: Web3.utils.toChecksumAddress(walletAddress) }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }));
    return result;
  }

  const getMigrateArray = async () => {
    const res = (await getUnMigratedYetis()).data.unMigrated;
    updateMigrate(res);
    setReMigrateId(res);
  }

  useEffect(() => {
    (async () => {
      await getUserTokens();
      setLoading(false);
    })();
  }, [reMigrateId])

  const handleMigrateL1toL2 = async () => {
    const web3 = new Web3(provider);
    //@ts-ignore
    const contractTown = await new web3.eth.Contract(contracts.abiYetiTown(L1), `${contracts.addressYetiTown(L1)}`);
    //@ts-ignore
    const contractL1Migrator = await new web3.eth.Contract(contracts.abiMigrator(L1), `${contracts.addressMigrator(L1)}`);

    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });
    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: contracts.addressYetiTown(L1),
        abi: contracts.abiYetiTown(L1),
        calls: [
          {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, contracts.addressMigrator(L1)]
          }
        ]
      }
    ];
    const results: ContractCallResults = await multicall.call(contractCallContext);
    const isApprovedForAll = results.results.contractTown.callsReturnContext[0].returnValues[0];
    try {
      setLoading(true);
      if (!isApprovedForAll)
        await contractTown.methods
          .setApprovalForAll(contracts.addressMigrator(L1), true)
          .send({ from: walletAddress });
      await contractL1Migrator.methods
        .StartGame(selectedUnstakedTokens)
        .send({ from: walletAddress });
      console.log();
      //@ts-ignore
      console.log();
      //@ts-ignore
      let includes = (await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call()) === Web3.utils.toChecksumAddress(walletAddress);
      while (includes) {
        await delay(1000);
        //@ts-ignore
        includes = (await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call()) === Web3.utils.toChecksumAddress(walletAddress);
      }
    } catch (error) {
      handleShowErrorNotification(error);
    }
    setSelectedUnstakedTokens([]);
    await getUserTokens();
    await initRequests();
    setLoading(false);
  };

  const handleMigrateL2toL1 = async () => {
    try {
      setLoading(true);
      const nonce = Date.now();
      const signature = await transactionSignerClaim(walletAddress, nonce);
      await axios.post(SEND_SIGNED_API + 'burn', {
        address: walletAddress,
        sign: signature,
        tokenId: selectedUnstakedTokens[0],
        unStake: false,
        level: 0,
        exp: 0,
        rarity: 0,
        pass: nonce
      });
      const web3 = new Web3(provider);
      //@ts-ignore
      let contractTown = await new web3.eth.Contract(contracts.abiYetiTown(L1), `${contracts.addressYetiTown(L1)}`);
      let includes =
        (await contractTown.methods.balanceOf(walletAddress, selectedUnstakedTokens[0]).call()) !==
        '0';
      while (includes) {
        await delay(1000);
        includes =
          (await contractTown.methods
            .balanceOf(walletAddress, selectedUnstakedTokens[0])
            .call()) !== '0';
      }
      //@ts-ignore
      includes = !(await axios.post(SEND_SIGNED_API + 'unMigrated', { address: Web3.utils.toChecksumAddress(walletAddress) })).data.unMigrated.includes(selectedUnstakedTokens[0]);
      while (includes) {
        await delay(1000);
        //@ts-ignore
        includes = !(await axios.post(SEND_SIGNED_API + 'unMigrated', { address: Web3.utils.toChecksumAddress(walletAddress) })).data.unMigrated.includes(selectedUnstakedTokens[0]);
      }

      for (let i = 0; i < 4; ++i) {
        if (await changeChainId(provider, contracts.chainId(!L1))) {
          break;
        }
        handleShowErrorNotification({ message: 'Please unlock metamask and switch network' });
      }
      dispatch(set11ButtonSwich(!L1));
      L1 = !L1;

      const txMigration = await axios.post(SEND_SIGNED_API + 'reverseBridge', {
        //@ts-ignore
        address: Web3.utils.toChecksumAddress(walletAddress),
        tokenId: selectedUnstakedTokens[0]
      });

      //@ts-ignore
      const contractL1Migrator = await new web3.eth.Contract(contracts.abiMigrator(true),
        `${contracts.addressMigrator(true)}`
      );
      await contractL1Migrator.methods
        .WithdrawToken(Object.values(txMigration.data))
        .send({ from: walletAddress });

      //@ts-ignore
      contractTown = await new web3.eth.Contract(contracts.abiYetiTown(L1), `${contracts.addressYetiTown(L1)}`);
      //@ts-ignore
      includes = await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call() !== Web3.utils.toChecksumAddress(walletAddress);
      while (includes) {
        await delay(1000);
        //@ts-ignore
        includes = (await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call()) !== Web3.utils.toChecksumAddress(walletAddress);
      }
    } catch (error) {
      if (!(await checkChain(provider, contracts.chainId(L1)))) {
        setLoading(true);
        for (let i = 0; i < 4; ++i) {
          if (await changeChainId(provider, contracts.chainId(!L1))) {
            break;
          }
          if (i == 3) {
            dispatch(set11ButtonSwich(L1));
            handleShowErrorNotification({ message: 'Please unlock metamask and switch network' });
          }
        }
        await getUserTokens();
        setLoading(false);
      }
      handleShowErrorNotification(error);
    }
    setModalOpen(false);
    setLoading(false);
  };

  const handleReMigrate = async () => {
    try {
      setLoading(true);
      const web3 = new Web3(provider);
      //@ts-ignore
      const contractTown = await new web3.eth.Contract(contracts.abiYetiTown(L1), `${contracts.addressYetiTown(L1)}`);

      const txMigration = await axios.post(SEND_SIGNED_API + 'reverseBridge', {
        //@ts-ignore
        address: Web3.utils.toChecksumAddress(walletAddress),
        tokenId: selectedUnstakedTokens[0]
      });

      //@ts-ignore
      const contractL1Migrator = await new web3.eth.Contract(contracts.abiMigrator(true),
        `${contracts.addressMigrator(true)}`
      );
      await contractL1Migrator.methods
        .WithdrawToken(Object.values(txMigration.data))
        .send({ from: walletAddress });

      //@ts-ignore
      let includes = await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call() !== Web3.utils.toChecksumAddress(walletAddress);
      while (includes) {
        await delay(1000);
        //@ts-ignore
        includes = (await contractTown.methods.ownerOf(selectedUnstakedTokens[0]).call()) !== Web3.utils.toChecksumAddress(walletAddress);
      }
    }
    catch (error) {
      handleShowErrorNotification(error);
    }
    setSelectedUnstakedTokens([]);
    await getMigrateArray();
    // setLoading(false);
  }

  const clearSelectedTokens = () => {
    // setSelectedInHospitalToken(null);
    setSelectedUnstakedTokens([]);
    // setSelectedStakedTokens([]);
  };

  const clearState = () => {
    clearSelectedTokens();
    // setStakedArray([]);
    setUserNftsData([]);
  };

  useEffect(() => {
    return () => clearState();
  }, []);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);

  const getUserTokens: any = async () => {
    if (provider && walletAddress && !constantsError) {
      // Env
      if (!(await checkChain(provider, contracts.chainId(L1)))) {
        setLoading(true);
        for (let i = 0; i < 4; ++i) {
          if (await changeChainId(provider, contracts.chainId(L1))) {
            break;
          }
          if (i == 3) {
            dispatch(set11ButtonSwich(L1));
            handleShowErrorNotification({ message: 'Please unlock metamask and switch network' });
          }
        }
        setLoading(false);
      }
      const localL1 = type === ButtonType.First || type === ButtonType.Third;
      try {
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });
        const tokensIDs: number[] = [];
        if (localL1 && type != ButtonType.Third) {
          const contractCallContext: ContractCallContext[] = [
            {
              reference: 'contractTown',
              contractAddress: contracts.addressYetiTown(localL1),
              abi: contracts.abiYetiTown(localL1),
              calls: [
                {
                  reference: 'balanceOf',
                  methodName: 'balanceOf',
                  methodParameters: [walletAddress]
                }
              ]
            }
            // {
            //     reference: 'contractFrxst',
            //     contractAddress: contracts.addressFrxst(L1),
            //     abi: abiFrxst,
            //     calls: [
            //         { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            //     ]
            // }
          ];

          const results: ContractCallResults = await multicall.call(contractCallContext);

          const yetisBalance = parseInt(
            results.results.contractTown.callsReturnContext[0].returnValues[0].hex,
            16
          );

          if (yetisBalance > 0) {
            const contractCallTokenIDs: ContractCallContext[] = [
              {
                reference: 'contractTown',
                contractAddress: contracts.addressYetiTown(localL1),
                abi: contracts.abiYetiTown(localL1),
                calls: Array.from({ length: yetisBalance }, (_, i) => ({
                  reference: 'tokenOfOwnerByIndex',
                  methodName: 'tokenOfOwnerByIndex',
                  methodParameters: [walletAddress, i]
                }))
              }
            ];

            const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
            tokensIDsRes.results.contractTown.callsReturnContext.forEach((returnContext) =>
              tokensIDs.push(parseInt(returnContext.returnValues[0].hex))
            );
          }
        } else if (type != ButtonType.Third) {
          const contractCallContext: ContractCallContext[] = [
            {
              reference: 'contractTown',
              contractAddress: contracts.addressYetiTown(localL1),
              abi: contracts.abiYetiTown(localL1),
              calls: [
                {
                  reference: 'viewTotalTokensOfOwner',
                  methodName: 'viewTotalTokensOfOwner',
                  methodParameters: [walletAddress]
                }
              ]
            }
          ];
          const tokensIDsRes = await multicall.call(contractCallContext);

          tokensIDsRes.results.contractTown.callsReturnContext[0].returnValues.forEach((value) =>
            tokensIDs.push(parseInt(value.hex))
          );
        }

        if (type === ButtonType.Third) {
          if(reMigrateId && reMigrateId.length > 0){
            reMigrateId.forEach((value) => {
              tokensIDs.push(value)
            })
          }else {
            onCloseModal()
          }
        }
        if (tokensIDs.length > 0) {
          const gameTokensInfo: {
            edition: string;
            date: number;
            image: string;
            name: string;
            level?: number;
            experience?: number;
          }[] = [];

          for (const tokenID of tokensIDs) {
            if (uninitiatedTokens.includes(tokenID)) {
              continue;
            }
            const tokenURI = IPFS_ROUTE + tokenID + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            gameTokensInfo.push(nftInfo);
          }

          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'levels',
              contractAddress: contracts.addressGameLogic(localL1),
              abi: contracts.abiGameLogic(localL1),
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [nftInfo.edition]
              }))
            },
            {
              reference: 'experience',
              contractAddress: contracts.addressGameLogic(localL1),
              abi: contracts.abiGameLogic(localL1),
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: L1 ? 'experience' : 'exp',
                methodName: L1 ? 'experience' : 'exp',
                methodParameters: [nftInfo.edition]
              }))
            }
          ];
          const tokenInfoRes: ContractCallResults = await multicall.call(contractCallTokenInfo);

          const tokensLevels = tokenInfoRes.results.levels.callsReturnContext.map((returnContext) =>
            localL1 ? returnContext.returnValues[0] : parseInt(returnContext.returnValues[0].hex)
          );
          const tokensExps = tokenInfoRes.results.experience.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          for (const [index, gameTokenInfo] of gameTokensInfo.entries()) {
            gameTokensInfo[index].image = gameTokenInfo.image.replace(
              'ipfs://',
              'https://ipfs.io/ipfs/'
            );
            gameTokensInfo[index].level = tokensLevels[index];
            gameTokensInfo[index].experience = tokensExps[index];
          }
          setUserNftsData(gameTokensInfo);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const navigationUnstakedPrevRef = useRef(null);
  const navigationUnstakedNextRef = useRef(null);

  const handleSelectUnstakedToken = (tokenId: number) => {
    if (selectedUnstakedTokens?.includes(tokenId)) {
      const updatedUnstakedTokens = selectedUnstakedTokens.filter((token) => {
        return token !== tokenId;
      });
      setSelectedUnstakedTokens(updatedUnstakedTokens);
    } else {
      if (type === ButtonType.First) {
        setSelectedUnstakedTokens([...selectedUnstakedTokens, tokenId]);
      } else {
        setSelectedUnstakedTokens([tokenId]);
      }
    }
    // setSelectedStakedTokens([]);
  };

  const initRequests = async () => {
    clearState();
    setFetching(true);
    try {
      const { unstakedTokensCount, stakedTokensCount } = await getTokensCount(
        provider,
        walletAddress,
        L1
      );
      const newTotalTokensCount = unstakedTokensCount + stakedTokensCount;
      if (totalTokensCount !== newTotalTokensCount) {
        if (L1) {
          const unInitiatedTokens = await getUninitiatedTokens(provider, walletAddress, L1);
          if (unInitiatedTokens.length > 0) {
            dispatch(setTokensInitiated(false));
            dispatch(setUninitiatedTokens(unInitiatedTokens));
          }
        }
      }
      await getUserTokens();
      // await getStakedTokens();
    } catch (error) {
      handleShowErrorNotification(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (provider && walletAddress) {
      initRequests();
    }
  }, [provider, walletAddress, selectedActionId, allTokensInitiated, ignoreTokensInitiating]);

  const onCloseModal = () => {
    setModalOpen(false)
  }

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <>
      {(loading || fetching) && <LoadingSplash />}
      {!fetching && (
        <div className={`modal-map-field`}>
          <div ref={ref} className="modal-map-field__content">
            <div className="modal-map-migrate__table">
              <BackButton onCloseModal={onCloseModal} />
              <div className="modal-map-table migrate-table">

                <div className="modal-map-table__title">
                  {type === ButtonType.Third ? 'There was an issue migrating the following Yetis:' : (
                    `Choose ${type === ButtonType.First ? ' yetis ' : ' yeti '} to migrate`
                  )}
                </div>
                <div className="modal-map-table__header migrate-header">
                  {type === ButtonType.First && (
                    <div className="modal-map-table__header-row">
                      <div>
                        Choose amount:
                        <span>{selectedUnstakedTokens.length}</span>
                      </div>
                    </div>
                  )}
                </div>
                {userNftsData && userNftsData.length > 0 && selectedActionId !== ACTION_IDS.HEAL && (
                  <>
                    <div className={`modal-map-table__content modal-map-field__row`}>
                      {userNftsData.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationUnstakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationUnstakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}

                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationUnstakedNextRef.current,
                          prevEl: navigationUnstakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {userNftsData?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="migrate__row">
                                <NftItem
                                  isSelected={selectedUnstakedTokens.includes(nft.edition)}
                                  nft={nft}
                                  onClick={() => handleSelectUnstakedToken(nft.edition)}
                                  showTimer={false}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                <div className="modal-map-table__buttons">
                  {selectedActionId !== ACTION_IDS.HEAL && (
                    <>
                      {type === ButtonType.First ? (
                        <button
                          className={`btn-outline ${selectedUnstakedTokens.length > 0 ? '' : 'invisible'
                            }`}
                          type="button"
                          onClick={handleMigrateL1toL2}
                        >
                          START MIGRATION
                        </button>
                      ) : type === ButtonType.Third ? (
                        <button
                          className={`btn-outline ${selectedUnstakedTokens.length > 0 ? '' : 'invisible'
                            }`}
                          data-tip
                          data-for={'nft_tooltip_claim_tooltip'}
                          type="button"
                          onClick={handleReMigrate}
                        // disabled={!showClaimBtn}
                        >
                          Migrate
                        </button>
                      ) : (
                        <button
                          className={`btn-outline ${selectedUnstakedTokens.length > 0 ? '' : 'invisible'
                            }`}
                          data-tip
                          data-for={'nft_tooltip_claim_tooltip'}
                          type="button"
                          onClick={handleMigrateL2toL1}
                        // disabled={!showClaimBtn}
                        >
                          withdraw
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <img
                src={modalMap}
                alt=""
                className={`modal-map-field__img`}
                onLoad={() => setIsMapImageLoaded(true)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MigrateModal;
