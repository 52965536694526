import { FC, useEffect, useRef, useState } from 'react';
import { Dialog } from './index';
import './index.scss';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'app/hooks/redux';
import { BackButton } from '../backButton';
import { useProvider } from 'app/hooks/provider';
import Web3 from 'web3';
import {
  setTokensInitiated,
  setGameAllowed,
  setGameApproved,
  setIgnoreTokensInitiating
} from 'app/redux/reducers/TokensSlice';
import { handleShowErrorNotification } from '../../helpers/showError';
import LoadingSplash from '../map/splashScreens/LoadingSplash';
import L1L2 from 'app/contracts/L1L2';
import { AbiInput, AbiItem } from 'ethereum-multicall/dist/esm/models';
import { calculateGas } from 'app/contracts/polygonGasFee';

const contracts = new L1L2();

const RARITY_CHECK_ADDRESS = `${process.env.REACT_APP_RARITY_CHECK_ADDRESS}`;
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

interface InitialGameProps {
  onCloseModal: () => void;
  modalOpen: boolean;
  setModalOpen: (x: boolean) => void
}

const InitiateGame: FC<InitialGameProps> = ({ onCloseModal, modalOpen, setModalOpen }) => {
  const dispatch = useAppDispatch();
  const provider = useProvider();

  const [loading, setLoading] = useState<boolean>(false);

  const L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);

  const initLoading = useAppSelector((state) => state.TokensReducer.loading);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);

  const gameApproved = useAppSelector((state) => state.TokensReducer.gameApproved);
  const gameAllowed = useAppSelector((state) => state.TokensReducer.gameAllowed);
  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount: Number = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const handleApproveGame = async (): Promise<void> => {
    const web3 = new Web3(provider);
    //@ts-ignore
    const contractTown = await new web3.eth.Contract(contracts.abiYetiTown(L1), `${contracts.addressYetiTown(L1)}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: contracts.addressYetiTown(L1),
        abi: contracts.abiYetiTown(L1),
        calls: [
          {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);

    const isApprovedForAll = results.results.contractTown.callsReturnContext[0].returnValues[0];

    if (!isApprovedForAll) {
      setLoading(true);
      try {
        const gasFee = await calculateGas(web3);
        await contractTown.methods
          .setApprovalForAll(contracts.addressGameLogic(L1), true)
          .send({
            from: walletAddress,
            ...gasFee
          });
        dispatch(setGameApproved(true));
      } catch (error) {
        handleShowErrorNotification(error);
        dispatch(setGameApproved(false));
      }
    }
    setLoading(false);
  };

  const handleAllowGame = async (): Promise<void> => {
    const web3 = new Web3(provider);
    //@ts-ignore
    const contractFrxst = await new web3.eth.Contract(contracts.abiFrxst(L1), `${contracts.addressFrxst(L1)}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractFrxst',
        contractAddress: contracts.addressFrxst(L1),
        abi: contracts.abiFrxst(L1),
        calls: [
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [walletAddress, `${contracts.addressGameLogic(L1)}`]
          }
        ]
      }
    ];
    const results: ContractCallResults = await multicall.call(contractCallContext);

    const allowance = results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex;

    const frxstAllowedToCharge = allowance === contracts.frxstAllowance();

    if (!frxstAllowedToCharge) {
      try {
        setLoading(true);
        let gasFee;
        if(!L1) {
          gasFee = await calculateGas(web3);
        }
        await contractFrxst.methods
          .approve(contracts.addressGameLogic(L1), contracts.frxstAllowance())
          .send({ from: walletAddress, ...gasFee });
        dispatch(setGameAllowed(true));
      } catch (error) {
        handleShowErrorNotification(error);
        dispatch(setGameAllowed(false));
      }
    }
    setLoading(false);
  };

  const handleInitiateTokens = async (): Promise<void> => {
    setLoading(true);
    const web3 = new Web3(provider);
    //@ts-ignore
    const contractGameLogic = await new web3.eth.Contract(contracts.abiGameLogic(L1), `${contracts.addressGameLogic(L1)}`);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: contracts.addressYetiTown(L1),
        abi: contracts.abiYetiTown(L1),
        calls: [
          {
            reference: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
            methodName: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
            methodParameters: [walletAddress]
          }, {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
          }
        ]
      },
      {
        reference: 'contractFrxst',
        contractAddress: contracts.addressFrxst(L1),
        abi: contracts.abiFrxst(L1),
        calls: [
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);
    const yetisBalance = L1
      ? parseInt(results.results.contractTown.callsReturnContext[0].returnValues[0].hex, 16)
      : results.results.contractTown.callsReturnContext[0].returnValues.length;

    const tokensIDs: number[] = [];
    if (L1) {
      const contractCallTokenIDs: ContractCallContext[] = [
        {
          reference: 'contractTown',
          contractAddress: contracts.addressYetiTown(L1),
          abi: contracts.abiYetiTown(L1),
          calls: Array.from({ length: yetisBalance }, (_, i) => ({
            reference: 'tokenOfOwnerByIndex',
            methodName: 'tokenOfOwnerByIndex',
            methodParameters: [walletAddress, i]
          }))
        }
      ];

      const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
      tokensIDsRes.results.contractTown.callsReturnContext.forEach((returnContext) =>
        tokensIDs.push(parseInt(returnContext.returnValues[0].hex))
      );
    } else {
      results.results.contractTown.callsReturnContext[0].returnValues.forEach(
        (value) => tokensIDs.push(parseInt(value.hex))
      );
    }
    const gameTokensInfo: { edition: string; date: number; image: string; name: string }[] = [];

    for (const tokenID of tokensIDs) {
      const tokenURI = IPFS_ROUTE + tokenID + '.json';

      let res;
      try {
        res = await axios.get(tokenURI);
      } catch (e) {
        throw new Error('Something wrong with IPFS, network try again');
      }
      const nftInfo = res.data;
      gameTokensInfo.push(nftInfo);
    }
    if (L1) {
      const contractCallInitiateGameTokens: ContractCallContext[] = [
        {
          reference: 'contractGameLogic',
          contractAddress: contracts.addressGameLogic(L1),
          abi: contracts.abiGameLogic(L1),
          calls: gameTokensInfo.map((nftInfo) => ({
            reference: 'initiateGame',
            methodName: 'initiateGame',
            methodParameters: [nftInfo.edition]
          }))
        }
      ];

      const initiateGameTokensRes: ContractCallResults = await multicall.call(
        contractCallInitiateGameTokens
      );
      const initiateGameTokens = initiateGameTokensRes.results.contractGameLogic.callsReturnContext.map(
        (returnContext) => returnContext.returnValues[0]
      );

      const unInitatedTokens = [];

      for (const [index, tokenInitiated] of initiateGameTokens.entries()) {
        if (!tokenInitiated) {
          const edition = gameTokensInfo[index]?.edition;
          const {
            data: { rarity, signature }
          } = await axios.post(RARITY_CHECK_ADDRESS, {
            tokenID: edition
          });
          unInitatedTokens.push([edition, rarity, signature]);
        }
      }

      if (unInitatedTokens.length > 0) {
        try {
          await contractGameLogic.methods
            .initiateGameAt(unInitatedTokens)
            .send({ from: walletAddress });
          dispatch(setTokensInitiated(true));
        } catch (error) {
          handleShowErrorNotification(error);
          dispatch(setTokensInitiated(false));
        }
      }
    } setLoading(false);
  };

  const handleSkipInitiateTokens = async (): Promise<void> => {
    dispatch(setIgnoreTokensInitiating(true));
  };

  const showSkip = +totalTokensCount - uninitiatedTokens.length > uninitiatedTokens.length; // ONLY IF WE INITIATED OTHER TOKENS PREVIOUSLY

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <>
      {(loading || initLoading) && <LoadingSplash />}
      {totalTokensCount > 0 && (!gameApproved || !gameAllowed || (L1 && !allTokensInitiated)) && (
        <Dialog onCloseModal={onCloseModal} setModalOpen={setModalOpen} modalOpen={modalOpen} >
          <>
            <BackButton onCloseModal={onCloseModal} />
            <div ref={ref} className="dialog__content init-game-dialog">
              <p className="init-game-dialog__header-1">TO START A GAME:</p>
              <p className="init-game-dialog__header-2">INITIATE GAME CONTRACT:</p>
              <button
                className="btn-outline init-game-dialog__btn"
                onClick={handleApproveGame}
                disabled={gameApproved}
                type="button"
              >
                ALLOW
              </button>
              <p className="init-game-dialog__header-2">ALLOW $FRXST:</p>
              <button
                className="btn-outline init-game-dialog__btn"
                onClick={handleAllowGame}
                disabled={!gameApproved || gameAllowed}
                type="button"
              >
                ALLOW
              </button>
              {L1 && <p className="init-game-dialog__header-2">AND INITIATE NFTS:</p>}
              {(showSkip && L1) && (
                <p className="init-game-dialog__header-3">
                  We noticed you have a new NFT that is uninitiated, please initiate it
                </p>
              )}
              {L1 &&
                <div className="flex-center">
                  <button
                    className="btn-outline init-game-dialog__btn"
                    onClick={handleInitiateTokens}
                    disabled={!gameApproved || !gameAllowed || allTokensInitiated}
                    type="button"
                  >
                    ALLOW
                  </button>
                  {showSkip && (
                    <button
                      className="btn-outline init-game-dialog__btn"
                      onClick={handleSkipInitiateTokens}
                      type="button"
                      disabled={!gameAllowed || !gameApproved}
                    >
                      Skip
                    </button>
                  )}
                </div>
              }
            </div>
          </>
        </Dialog>
      )}
      {totalTokensCount === 0 && (gameApproved || gameAllowed || allTokensInitiated) && (
        <Dialog onCloseModal={onCloseModal} setModalOpen={setModalOpen} modalOpen={modalOpen} >
          <div className="dialog__content">
            <p className="init-game-dialog__header-1">You don’t have Yetis</p>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default InitiateGame;
