import Web3 from 'web3';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import axios from 'axios';
import L1L2 from 'app/contracts/L1L2';

const contracts = new L1L2();
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

export const getUninitiatedTokens = async (
  provider: any,
  walletAddress: String | null,
  L1: boolean
): Promise<string[]> => {
  const web3 = new Web3(provider);

  const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

  const contractCallContext: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: contracts.addressYetiTown(L1),
      abi: contracts.abiYetiTown(L1),
      calls: [
        {
          reference: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
          methodName: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
          methodParameters: [walletAddress]
        },
        {
          reference: 'isApprovedForAll',
          methodName: 'isApprovedForAll',
          methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
        }
      ]
    },
    {
      reference: 'contractFrxst',
      contractAddress: contracts.addressFrxst(L1),
      abi: contracts.abiFrxst(L1),
      calls: [
        {
          reference: 'allowance',
          methodName: 'allowance',
          methodParameters: [walletAddress, `${contracts.addressGameLogic(L1)}`]
        }
      ]
    }
  ];

  const results: ContractCallResults = await multicall.call(contractCallContext);

  const yetisBalance = L1
    ? parseInt(results.results.contractTown.callsReturnContext[0].returnValues[0].hex, 16)
    : results.results.contractTown.callsReturnContext[0].returnValues.length;

  if (yetisBalance === 0) {
    return [];
  }
  const tokensIDs: number[] = [];
  if (L1) {
    const contractCallTokenIDs: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: contracts.addressYetiTown(L1),
        abi: contracts.abiYetiTown(L1),
        calls: Array.from({ length: yetisBalance }, (_, i) => ({
          reference: 'tokenOfOwnerByIndex',
          methodName: 'tokenOfOwnerByIndex',
          methodParameters: [walletAddress, i]
        }))
      }
    ];

    const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
    tokensIDsRes.results.contractTown.callsReturnContext.forEach((returnContext) =>
      tokensIDs.push(parseInt(returnContext.returnValues[0].hex))
    );
  }else {
    results.results.contractTown.callsReturnContext[0].returnValues.forEach(
      (value) => tokensIDs.push(parseInt(value.hex))
  );
  }

  const gameTokensInfo: { edition: string; date: number; image: string; name: string }[] = [];

  for (const tokenID of tokensIDs) {
    const tokenURI = IPFS_ROUTE + tokenID + '.json';

    let res;
    try {
      res = await axios.get(tokenURI);
    } catch (e) {
      throw new Error('Something wrong with IPFS, network try again');
    }
    const nftInfo = res.data;
    gameTokensInfo.push(nftInfo);
  }

  const contractCallInitiateGameTokens: ContractCallContext[] = [
    {
      reference: 'contractGameLogic',
      contractAddress: contracts.addressGameLogic(L1),
      abi: contracts.abiGameLogic(L1),
      calls: gameTokensInfo.map((nftInfo) => ({
        reference: 'initiateGame',
        methodName: 'initiateGame',
        methodParameters: [nftInfo.edition]
      }))
    }
  ];

  const initiateGameTokensRes: ContractCallResults = await multicall.call(
    contractCallInitiateGameTokens
  );
  const initiateGameTokens = initiateGameTokensRes.results.contractGameLogic.callsReturnContext.map(
    (returnContext) => returnContext.returnValues[0]
  );

  const unInitatedTokens: string[] = [];

  for (const [index, tokenInitiated] of initiateGameTokens.entries()) {
    if (!tokenInitiated) {
      const { edition } = gameTokensInfo[index];
      unInitatedTokens.push(edition);
    }
  }

  return unInitatedTokens;
};
