import axios from 'axios';
import Web3 from 'web3';

export const calculateGas = async (web3: Web3) => {
  // get max fees from gas station
  let maxFeePerGas = web3.utils.toBN(40000000000); // fallback to 40 gwei
  let maxPriorityFeePerGas = web3.utils.toBN(40000000000); // fallback to 40 gwei

  const { data } = await axios({
    method: 'get',
    url: process.env.REACT_APP_L2_CHAIN_ID == "0x89"
    ? 'https://gasstation-mainnet.matic.network/v2'
    : 'https://gasstation-mumbai.matic.today/v2',  });

  maxFeePerGas = web3.utils
    .toBN(web3.utils.toWei(Math.ceil(data.fast.maxFee).toString()))
    .div(web3.utils.toBN(10 ** 9));
  maxPriorityFeePerGas = web3.utils
    .toBN(web3.utils.toWei(Math.ceil(data.fast.maxPriorityFee).toString()))
    .div(web3.utils.toBN(10 ** 9));
  console.log(maxFeePerGas, maxPriorityFeePerGas);
  return {
    maxFeePerGas,
    maxPriorityFeePerGas
  };
};
