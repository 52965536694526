import { createSlice } from '@reduxjs/toolkit';
import { getConnection } from './ActionCreators';

interface AuthState {
  token: null | String;
  isConnected: Boolean;
  metaMaskEnabled: Boolean;
  L1: Boolean;
  error: any;
}

const initialState: AuthState = {
  token: null,
  isConnected: false,
  metaMaskEnabled: false,
  L1: true,
  error: null
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMetaMaskEnabled: (state) => {
      state.metaMaskEnabled = true;
    },
    setToken: (state, action) => {
      state.token = action.payload.accounts[0];
      state.isConnected = action.payload.isConnected;
    },
    logout: (state) => {
      state.token = initialState.token;
      state.isConnected = false;
    },
    switchNetwork: (state) => {
      state.L1 = !state.L1;
    }
  },
  extraReducers: {
    [getConnection.fulfilled.type]: (state, action) => {
      state.isConnected = action.payload.isConnected;
      state.token = action.payload.accounts[0];
      state.error = initialState.error;
    },
    [getConnection.rejected.type]: (state, action) => {
      state.error = action.payload;
    }
  }
});
export const { logout, setMetaMaskEnabled, setToken, switchNetwork } = AuthSlice.actions;

export default AuthSlice.reducer;
