import { useEffect, useState } from 'react';
import WithErrorCheckModals from 'app/components/errorCheck';
import MigrateModal from './MigrateModal';
import { ButtonType } from './types';
import Web3 from 'web3';

import './styles.scss';
import { changeChainId, checkChain } from 'app/contracts/chain';
import { useProvider } from 'app/hooks/provider';
import LoadingSplash from 'app/components/map/splashScreens/LoadingSplash';
import { useAppSelector } from 'app/hooks/redux';
import { set11ButtonSwich } from 'app/redux/reducers/TokensSlice';
import axios from 'axios';

const SEND_SIGNED_API = `${process.env.REACT_APP_SEND_SIGNED_API}`;
const L1_CHAIN_ID = `${process.env.REACT_APP_L1_CHAIN_ID}`
const L2_CHAIN_ID = `${process.env.REACT_APP_L2_CHAIN_ID}`



const Migrate = () => {
    const provider = useProvider();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [buttonType, setButtonType] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [migrateArray, setMigrateArray] = useState<any>([])

    const L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);
    const walletAddress = useAppSelector((state) => state.AuthReducer.token);

    const getUnMigratedYetis = async () => {
        //@ts-ignore
        const result = await (axios.post(SEND_SIGNED_API + 'unMigrated', {address: Web3.utils.toChecksumAddress(walletAddress)},{
            headers: {
              'Content-Type': 'application/json'
            }
          }));
        return result;
    }

    useEffect(() => {
        getMigrateArray();
    }, [modalOpen])

    const getMigrateArray = async () => {
        const res = (await getUnMigratedYetis()).data.unMigrated;
        setMigrateArray(res)
    }

    const handleCloseModal = async (): Promise<void> => {
        // // Env
        // if (!(await checkChain(provider, L1 ? L1_CHAIN_ID : L2_CHAIN_ID))) {
        //     setLoading(true);
        //     while (!(await changeChainId(provider, L1 ? L1_CHAIN_ID : L2_CHAIN_ID)));
        //     setLoading(false);
        // }
        setModalOpen(false);
    };

    const hendleOpenModal = (type: string): void => {
        setModalOpen(true);
        setButtonType(type)
    }

    return (
        <>
            {loading && <LoadingSplash />}
            <section className='migrate-section' id='migrate'>
                <div className='migrate-container'>
                    <div className='migrate-title'>
                        Migrate
                    </div>
                    <div className='migrate-box'>
                        <div className={`migrate-button ${!L1 ? 'disable' : ''}`} onClick={() => { if (L1) { hendleOpenModal(ButtonType.First) } }}>
                            Migrate to L2
                        </div>
                        <div className={`migrate-button ${L1 ? 'disable' : ''}`} onClick={() => { if (!L1) { hendleOpenModal(ButtonType.Second) } }}>
                            Migrate to L1
                        </div>
                        {((migrateArray ? migrateArray.length > 0 : false) && L1) &&
                            <div className='migrate-button' onClick={() => { { hendleOpenModal(ButtonType.Third) } }}>
                                Re-migrate
                            </div>
                        }
                    </div>
                </div>
            </section>
            <WithErrorCheckModals setModalOpen={setModalOpen} modalOpen={modalOpen} onCloseModal={handleCloseModal}>
                <MigrateModal modalOpen={modalOpen} setModalOpen={setModalOpen} selectedActionId={1} type={buttonType} _reMigrateId={buttonType === ButtonType.Third && migrateArray} updateMigrate={setMigrateArray} />
            </WithErrorCheckModals>
        </>
    )
}

export default Migrate
