import './styles.scss';
import discordIcon from '../../../assets/icons/social-discord.png';
import pageFooterImg from '../../../assets/images/footer-yeties.gif';
import pageFooterImgWebp from '../../../assets/images/footer-yeties.webp';

const JoinUs = () => {
  return (
    <section className="join-us-section">
      <div className="page-container page-container--lg">
        <div className="join-us">
          <div className="join-us__header">
            <h3 className="join-us__title heading-1">Join us</h3>
            <a href="https://discord.com/invite/yetitown" target="_blank">
              <img src={discordIcon} alt="Discord" className="join-us__discord-icon" />
            </a>
          </div>
        </div>
      </div>
      <div className="join-us__footer">
        <picture>
          <source src={pageFooterImg} type="image/gif" />
          <img src={pageFooterImgWebp} alt="" className="footer-img" loading="lazy" />
        </picture>
      </div>
      <div className="join-us__space"></div>
    </section>
  );
};

export default JoinUs;
