import './styles.scss';
import mapImage from '../../assets/images/new-map.png';
import mapImageWebp from '../../assets/images/new-map.webp';
import { useState } from 'react';
import { useProvider } from '../../hooks/provider';
import { useAppDispatch } from '../../hooks/redux';
import coliseum from '../../assets/images/coliseum.png';
import coliseumWebp from '../../assets/images/coliseum.webp';
import town from '../../assets/images/town.png';
import townWebp from '../../assets/images/town.webp';
import forest from '../../assets/images/forest.png';
import forestWebp from '../../assets/images/forest.webp';
import shack from '../../assets/images/shack.png';
import shackWebp from '../../assets/images/shack.webp';
import SwiperCore, { Navigation } from 'swiper';
import MapModal from './MapModal';
import WithErrorCheckModals from '../errorCheck';

SwiperCore.use([Navigation]);

const ACTION_IDS = {
  GATHER: 0,
  HUNT: 1,
  FIGHT: 2,
  HEAL: 4
};

const Map = () => {
  const [selectedActionId, setSelectedActionId] = useState<null | number>(null);
  const dispatch = useAppDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const provider = useProvider();

  const onSelectZone = (id: number) => {
    setSelectedActionId(id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedActionId(null);
  };

  return (
    <>
      <div className="map">
        <picture className="map__img">
          <source src={mapImage} type="image/png" />
          <img src={mapImageWebp} className="map__img" alt="map" />
        </picture>
        <div className="map__zones">
          <picture>
            <source src={coliseum} type="image/png" />
            <img
              src={coliseumWebp}
              alt=""
              className="map__zone1 map__zone"
              onClick={() => onSelectZone(ACTION_IDS.FIGHT)}
            />
          </picture>
          <picture>
            <source src={town} type="image/png" />
            <img
              src={townWebp}
              alt=""
              className="map__zone2 map__zone"
              onClick={() => onSelectZone(ACTION_IDS.GATHER)}
            />
          </picture>
          <picture>
            <source src={forest} type="image/png" />
            <img
              src={forestWebp}
              alt=""
              className="map__zone3 map__zone"
              onClick={() => onSelectZone(ACTION_IDS.HUNT)}
            />
          </picture>
          <picture>
            <source src={shack} type="image/png" />
            <img
              src={shackWebp}
              alt=""
              className="map__zone4 map__zone"
              onClick={() => onSelectZone(ACTION_IDS.HEAL)}
            />
          </picture>
        </div>

        <WithErrorCheckModals setModalOpen={setModalOpen} modalOpen={modalOpen} onCloseModal={handleCloseModal}>
          <MapModal setModalOpen={setModalOpen} modalOpen={modalOpen} onCloseModal={handleCloseModal} selectedActionId={selectedActionId} />
        </WithErrorCheckModals>
      </div>
    </>
  );
};

export default Map;
