import './styles.scss';
import twitterIcon from '../../../assets/icons/social-twitter.png';
import githubIcon from '../../../assets/icons/social-github.png';
import mikeImg from '../../../assets/images/mike.jpg';
import mikeImgWebp from '../../../assets/images/mike.webp';
import justinImg from '../../../assets/images/justin.png';
import justinImgWebp from '../../../assets/images/justin.webp';
import matthewImg from '../../../assets/images/matthew.jpg';
import matthewImgWebp from '../../../assets/images/matthew.webp';
import yuriiImg from '../../../assets/images/yurii.png';
import yuriiImgWebp from '../../../assets/images/yurii.webp';
import shanImg from '../../../assets/images/shan.png';
import shanImgWebp from '../../../assets/images/shan.webp';
import jacobImg from '../../../assets/images/jacob.png';
import jacobImgWebp from '../../../assets/images/jacob.webp';

const Team = () => {
  return (
    <section className="team-section" id="team">
      <div className="page-container page-container--md h-100">
        <div className="team">
          <p className="text-blue text-center heading-1 team__heading">Our Leadership</p>
          <div className="team__cards">
            <div className="team-card">
              <picture className="team-card__img">
                <source src={mikeImg} type="image/jpeg" />
                <img src={mikeImgWebp} className="team-card__img" alt="mike" />
              </picture>
              <a className="team-card__title" href="https://twitter.com/_0xMike" target="_blank">
                Mike
                <span>
                  <img src={twitterIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div>
            <div className="team-card">
              <picture className="team-card__img">
                <source src={justinImg} type="image/jpeg" />
                <img src={justinImgWebp} className="team-card__img" alt="justin" />
              </picture>
              <a className="team-card__title" href="https://twitter.com/JusteaNFT" target="_blank">
                Justin
                <span>
                  <img src={twitterIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div>
            <div className="team-card">
              <picture className="team-card__img">
                <source src={matthewImg} type="image/jpeg" />
                <img src={matthewImgWebp} className="team-card__img" alt="matthew" />
              </picture>
              <a
                className="team-card__title"
                href="https://twitter.com/NotaWhaleMatt"
                target="_blank"
              >
                Matthew
                <span>
                  <img src={twitterIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div>
            {/* <div className="team-card">
              <picture className="team-card__img">
                <source src={yuriiImg} type="image/png" />
                <img src={yuriiImgWebp} className="team-card__img" alt="yurii" />
              </picture>
              <a className="team-card__title" href="https://github.com/firsyura" target="_blank">
                Yurii
                <span>
                  <img src={githubIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div> */}
            <div className="team-card">
              <picture className="team-card__img">
                <source src={jacobImg} type="image/png" />
                <img src={jacobImgWebp} className="team-card__img" alt="jacob" />
              </picture>
              <a
                className="team-card__title"
                href="https://twitter.com/coder_saint"
                target="_blank"
              >
                Jacob
                <span>
                  <img src={twitterIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div>
            <div className="team-card">
              <picture className="team-card__img">
                <source src={shanImg} type="image/png" />
                <img src={shanImgWebp} className="team-card__img" alt="shan" />
              </picture>
              <a
                className="team-card__title"
                href="https://twitter.com/shanicucic96"
                target="_blank"
              >
                Shan
                <span>
                  <img src={twitterIcon} alt="Twitter" className="team-card__title-icon" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
