import L1_GAME_LOGIC_ABI from './L1GameLogic.json';
import L2_GAME_LOGIC_ABI from './L2GameLogic.json';
import L1_YETI_TOWN_ABI from './L1YetiTown.json';
import L2_YETI_TOWN_ABI from './L2YetiTown.json';
import L1_FRXST_ABI from './L1FRXST.json';
import L2_FRXST_ABI from './L2FRXST.json';
import L1_Migrator from './L1Migrator.json'

export default class L1L2 {
  L1_CHAIN_ID = `${process.env.REACT_APP_L1_CHAIN_ID}`;
  L2_CHAIN_ID = `${process.env.REACT_APP_L2_CHAIN_ID}`;

  L1_GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_L1_GAME_LOGIC_ADDRESS}`;
  L2_GAME_LOGIC_ADDRESS = `${process.env.REACT_APP_L2_GAME_LOGIC_ADDRESS}`;

  L1_YETI_TOWN_ADDRESS = `${process.env.REACT_APP_L1_YETI_TOWN_ADDRESS}`;
  L2_YETI_TOWN_ADDRESS = `${process.env.REACT_APP_L2_YETI_TOWN_ADDRESS}`;

  L1_FRXST_ADDRESS = `${process.env.REACT_APP_L1_FRXST_ADDRESS}`;
  L2_FRXST_ADDRESS = `${process.env.REACT_APP_L2_FRXST_ADDRESS}`;

  L1_MIGRARATOR_ADDRESS = `${process.env.REACT_APP_L1_MIGRARATOR_ADDRESS}`;

  FRXST_ALLOWANCE = `${process.env.REACT_APP_ALLOWANCE}`;

  chainId(L1: boolean) {
    return L1 ? this.L1_CHAIN_ID : this.L2_CHAIN_ID;
  }

  addressGameLogic(L1: boolean) {
    return L1 ? this.L1_GAME_LOGIC_ADDRESS : this.L2_GAME_LOGIC_ADDRESS;
  }

  addressYetiTown(L1: boolean) {
    return L1 ? this.L1_YETI_TOWN_ADDRESS : this.L2_YETI_TOWN_ADDRESS;
  }

  addressFrxst(L1: boolean) {
    return L1 ? this.L1_FRXST_ADDRESS : this.L2_FRXST_ADDRESS;
  }

  addressMigrator(L1: boolean) {
    return L1 ? this.L1_MIGRARATOR_ADDRESS : undefined;
  }

  frxstAllowance() {
    return this.FRXST_ALLOWANCE;
  }

  abiGameLogic(L1: boolean) {
    return L1 ? L1_GAME_LOGIC_ABI : L2_GAME_LOGIC_ABI;
  }

  abiYetiTown(L1: boolean) {
    return L1 ? L1_YETI_TOWN_ABI : L2_YETI_TOWN_ABI;
  }

  abiFrxst(L1: boolean) {
    return L1 ? L1_FRXST_ABI : L2_FRXST_ABI;
  }

  abiMigrator(L1: boolean) {
      return L1 ? L1_Migrator : undefined;
  }
}
