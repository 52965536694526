import { Store } from 'react-notifications-component';

export interface IAddNotification {
  title: string | number;
  message: string | undefined;
}

export const AddNotification = ({ title, message }: IAddNotification) => {
  if (!title || !message) return;
  Store.addNotification({
    title: title,
    message: message,
    type: 'info',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut']
  });
};
