import { useEffect, useLayoutEffect, useState } from 'react';
import { useAppDispatch } from 'app/hooks/redux';
import { useProvider } from '../hooks/provider';
import { setToken, switchNetwork, AuthSlice } from 'app/redux/reducers/AuthSlice';
import { setConstants, setConstantsError } from 'app/redux/reducers/ConstantsSlice';
import {
  setTokensInitiated,
  setGameApproved,
  setGameAllowed,
  setLoading,
  setTokensCount,
  setUninitiatedTokens,
  set11ButtonSwich
} from 'app/redux/reducers/TokensSlice';
import Header from './header';
import { useAppSelector } from '../hooks/redux';
import axios from 'axios';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import Web3 from 'web3';
import {
  Hero,
  HowItStarted,
  Team,
  GameZone,
  Clans,
  JoinUs,
  Phases,
  Footer,
  Info,
  Chest,
  Migrate
} from './sections';
import { ReactNotifications } from 'react-notifications-component';
import { handleShowErrorNotification } from '../helpers/showError';
import { getTokensCount } from '../helpers/getTokensCount';
import { changeChainId, checkChain } from 'app/contracts/chain';
import L1L2 from 'app/contracts/L1L2';

const contracts = new L1L2();

const RARITY_CHECK_ADDRESS = `${process.env.REACT_APP_RARITY_CHECK_ADDRESS}`;
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;

const Layout = () => {
  const provider = useProvider();
  const dispatch = useAppDispatch();

  const [pageFullyLoaded, setPageFullyLoaded] = useState(false);

  const L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);

  useEffect(() => {
    document.onreadystatechange = function () {
      if (document.readyState === 'complete') {
        setPageFullyLoaded(true);
      }
    };
  }, []);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);

  const getApiConstants = async (): Promise<void> => {
    const web3 = new Web3(provider);
    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });
    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractGameLogic',
        contractAddress: contracts.addressGameLogic(L1),
        abi: contracts.abiGameLogic(L1),
        calls: [
          { reference: 'MINIMUM_TO_EXIT', methodName: 'MINIMUM_TO_EXIT', methodParameters: [] },
          { reference: 'INJURY_TIME', methodName: 'INJURY_TIME', methodParameters: [] },
          {
            reference: 'rewardCalculationDuration',
            methodName: 'rewardCalculationDuration',
            methodParameters: []
          }
        ]
      }
    ];
    try {
      const results: ContractCallResults = await multicall.call(contractCallContext);
      const MINIMUM_TO_EXIT = parseInt(
        results.results.contractGameLogic.callsReturnContext[0].returnValues[0].hex,
        16
      );
      const INJURY_TIME = parseInt(
        results.results.contractGameLogic.callsReturnContext[1].returnValues[0].hex,
        16
      );

      const REWARD_TIME = parseInt(
        results.results.contractGameLogic.callsReturnContext[2].returnValues[0].hex,
        16
      );

      dispatch(setConstants({ MINIMUM_TO_EXIT, INJURY_TIME, REWARD_TIME }));
    } catch (error) {
      handleShowErrorNotification(error);
      dispatch(setConstantsError(true));
    }
  };

  const handleInit = async (): Promise<void> => {
    const web3 = new Web3(provider);

    const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

    const contractCallContext: ContractCallContext[] = [
      {
        reference: 'contractTown',
        contractAddress: contracts.addressYetiTown(L1),
        abi: contracts.abiYetiTown(L1),
        calls: [
          {
            reference: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
            methodName: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
            methodParameters: [walletAddress]
          }, {
            reference: 'isApprovedForAll',
            methodName: 'isApprovedForAll',
            methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
          }
        ]
      },
      {
        reference: 'contractFrxst',
        contractAddress: contracts.addressFrxst(L1),
        abi: contracts.abiFrxst(L1),
        calls: [
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [walletAddress, `${contracts.addressGameLogic(L1)}`]
          }
        ]
      }
    ];

    const results: ContractCallResults = await multicall.call(contractCallContext);
    const yetisBalance = L1
      ? parseInt(results.results.contractTown.callsReturnContext[0].returnValues[0].hex, 16)
      : results.results.contractTown.callsReturnContext[0].returnValues?.length;

    const isApprovedForAll = results.results.contractTown.callsReturnContext[1].returnValues[0];

    dispatch(setGameApproved(isApprovedForAll));

    const allowance = results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex;

    const frxstAllowedToCharge = allowance === contracts.frxstAllowance();

    dispatch(setGameAllowed(frxstAllowedToCharge));

    if (yetisBalance === 0) {
      dispatch(setTokensInitiated(true));
      return;
    }

    const tokensIDs: number[] = [];
    if (L1) {
      const contractCallTokenIDs: ContractCallContext[] = [
        {
          reference: 'contractTown',
          contractAddress: contracts.addressYetiTown(L1),
          abi: contracts.abiYetiTown(L1),
          calls: Array.from({ length: yetisBalance }, (_, i) => ({
            reference: 'tokenOfOwnerByIndex',
            methodName: 'tokenOfOwnerByIndex',
            methodParameters: [walletAddress, i]
          }))
        }
      ];

      const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
      tokensIDsRes.results.contractTown.callsReturnContext.forEach((returnContext) =>
        tokensIDs.push(parseInt(returnContext.returnValues[0].hex))
      );
    } else {
      results.results.contractTown.callsReturnContext[0].returnValues.forEach(
        (value) => tokensIDs.push(parseInt(value.hex))
      );
    }

    const gameTokensInfo: { edition: string; date: number; image: string; name: string }[] = [];

    for (const tokenID of tokensIDs) {
      const tokenURI = IPFS_ROUTE + tokenID + '.json';

      let res;
      try {
        res = await axios.get(tokenURI);
      } catch (e) {
        throw new Error('Something wrong with IPFS, network try again');
      }
      const nftInfo = res.data;
      gameTokensInfo.push(nftInfo);
    }

    if (L1) {
      const contractCallInitiateGameTokens: ContractCallContext[] = [
        {
          reference: 'contractGameLogic',
          contractAddress: contracts.addressGameLogic(L1),
          abi: contracts.abiGameLogic(L1),
          calls: gameTokensInfo.map((nftInfo) => ({
            reference: 'initiateGame',
            methodName: 'initiateGame',
            methodParameters: [nftInfo.edition]
          }))
        }
      ];

      const initiateGameTokensRes: ContractCallResults = await multicall.call(
        contractCallInitiateGameTokens
      );
      const initiateGameTokens = initiateGameTokensRes.results.contractGameLogic.callsReturnContext.map(
        (returnContext) => returnContext.returnValues[0]
      );

      const unInitatedTokens = [];

      for (const [index, tokenInitiated] of initiateGameTokens.entries()) {
        if (!tokenInitiated) {
          const { edition } = gameTokensInfo[index];
          const {
            data: { rarity, signature }
          } = await axios.post(RARITY_CHECK_ADDRESS, {
            tokenID: edition
          });
          unInitatedTokens.push([edition, rarity, signature]);
        }
      }

      dispatch(setUninitiatedTokens(unInitatedTokens.map((token) => token[0])));
      dispatch(setTokensInitiated(unInitatedTokens?.length === 0));
    }
  };

  const handleGetTokensCount = async (): Promise<void> => {
    const { unstakedTokensCount, stakedTokensCount } = await getTokensCount(
      provider,
      walletAddress,
      L1
    );
    dispatch(setTokensCount({ unstakedTokensCount, stakedTokensCount }));
  };

  useEffect(() => {
    if (provider) {
      const isConnected = provider.isConnected();
      provider.on('accountsChanged', (accounts: any) => {
        // Handle the new accounts, or lack thereof.
        // "accounts" will always be an array, but it can be empty.
        // const accountstest = ['0x5Fa3053B9284F002CB283d614d6361753A2092B1'];
        dispatch(setToken({ accounts, isConnected }));
      });
    }
  }, [provider]);

  const handleInitialRequests = async () => {
    if (walletAddress && provider) {
      dispatch(setLoading(true));
      try {
        // Env
        if (!(await checkChain(provider, contracts.chainId(L1)))) {
          for (let i = 0; i < 4; ++i) {
            if (await changeChainId(provider, contracts.chainId(L1))) {
              break;
            }
            if (i == 3) {
              dispatch(set11ButtonSwich(!L1));
              handleShowErrorNotification({ message: "Please unlock metamask and switch network" });
            }
          }
        }
        await getApiConstants();
        await handleInit();
        await handleGetTokensCount();
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  useEffect(() => {
    handleInitialRequests();
  }, [provider, walletAddress, L1]);

  return (
    <>
      <div className={`page-wrapper ${pageFullyLoaded ? '' : 'loading'}`}>
        <Header />
        <div className="main-content">
          <Hero />
          <HowItStarted />
          <Clans />
          <GameZone />
          <Chest />
          <Migrate />
          <Phases />
          <Info />
          <Team />
          <JoinUs />
          <Footer />
        </div>
      </div>
      <ReactNotifications />
    </>
  );
};

export default Layout;
