import Web3 from 'web3';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import L1L2 from 'app/contracts/L1L2';

const contracts = new L1L2();

export const getTokensCount = async (
  provider: any,
  walletAddress: String | null,
  L1: boolean
): Promise<{
  unstakedTokensCount: number;
  stakedTokensCount: number;
}> => {
  const web3 = new Web3(provider);

  const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

  const contractCallContext: ContractCallContext[] = [
    {
      reference: 'contractTown',
      contractAddress: contracts.addressYetiTown(L1),
      abi: contracts.abiYetiTown(L1),
      calls: [
        {
          reference: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
          methodName: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
          methodParameters: [walletAddress]
        },
        {
          reference: 'isApprovedForAll',
          methodName: 'isApprovedForAll',
          methodParameters: [walletAddress, contracts.addressGameLogic(L1)]
        }
      ]
    },
    {
      reference: 'contractGameLogic',
      contractAddress: contracts.addressGameLogic(L1),
      abi: contracts.abiGameLogic(L1),
      calls: [
        {
          reference: 'getStakedTokens',
          methodName: 'getStakedTokens',
          methodParameters: [walletAddress]
        }
      ]
    }
  ];

  const results: ContractCallResults = await multicall.call(contractCallContext);
  const unstakedTokensCount = L1
  ? parseInt(results.results.contractTown.callsReturnContext[0].returnValues[0].hex, 16)
  : results.results.contractTown.callsReturnContext[0].returnValues.length;

  const stakedTokens = results.results.contractGameLogic.callsReturnContext[0].returnValues.map(
    (returnValue) => parseInt(returnValue.hex)
  );
  return { unstakedTokensCount, stakedTokensCount: stakedTokens.length };
};
