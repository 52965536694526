import { FC, useEffect, useRef } from 'react';
import { Dialog } from './index';
import { getConnection } from 'app/redux/reducers/ActionCreators';
import { useAppDispatch } from 'app/hooks/redux';
import { BackButton } from '../backButton';

interface BackButtonProps {
  onCloseModal: () => void;
  className?: string;
  setModalOpen: (x: boolean) => void;
  modalOpen: boolean;
}

const ConnectMetaMask: FC<BackButtonProps> = ({ onCloseModal, setModalOpen, modalOpen }) => {
  const dispatch = useAppDispatch();
  const handleGetConnection = () => dispatch(getConnection());

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <Dialog onCloseModal={onCloseModal} setModalOpen={setModalOpen} modalOpen={modalOpen}>
      <>
        <BackButton onCloseModal={onCloseModal} />
        <div ref={ref} className="dialog__content">
          <p>You need to connect to MetaMask</p>
          <button className="btn-outline" type="button" onClick={handleGetConnection}>
            Connect
          </button>
        </div>
      </>
    </Dialog>
  );
};

export default ConnectMetaMask;
