import { useEffect, useState, useRef, useLayoutEffect, FC } from 'react';
import { useProvider } from '../../../hooks/provider';
import { useAppSelector, useAppDispatch } from '../../../hooks/redux';
import axios from 'axios';
import Web3 from 'web3';
import modalMap from '../../../assets/images/modal-map.png';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import nextArrow from '../../../assets/icons/next-arrow.png';
import prevArrow from '../../../assets/icons/prev-arrow.png';
import SwiperCore, { Navigation } from 'swiper';
import { Multicall, ContractCallResults, ContractCallContext } from 'ethereum-multicall';
import { NftItem } from '../../nftItem';
import { handleShowErrorNotification } from '../../../helpers/showError';
import LoadingSplash from '../../map/splashScreens/LoadingSplash';
import LEVELING_UP from '../../../contracts/levels';
import { getUninitiatedTokens } from '../../../helpers/getUninitiatedTokens';
import { getTokensCount } from '../../../helpers/getTokensCount';
import { setUninitiatedTokens, setTokensInitiated } from '../../../redux/reducers/TokensSlice';
import { transactionSigner, transactionSignerClaim } from '../../../contracts/signData';
import L1L2 from '../../../contracts/L1L2';
import { set11ButtonSwich } from 'app/redux/reducers/TokensSlice';
import { delay } from 'app/helpers/utils';
import { BackButton } from 'app/components/backButton';
import { ACTION_IDS } from 'app/contracts/actions';

SwiperCore.use([Navigation]);

const BALANCE_MULTIPLIER = 1000000000000000000;

const MAIN_NET_DELAY = 100;

const contracts = new L1L2();
const IPFS_ROUTE = `${process.env.REACT_APP_IPFS_ROUTE}`;
const SEND_SIGNED_API = `${process.env.REACT_APP_SEND_SIGNED_API}`;

interface BackButtonProps {
  onCloseModal: () => void;
  setModalOpen: (e: boolean) => void;
  modalOpen: boolean
}

const ChestModal: FC<BackButtonProps> = ({ onCloseModal, setModalOpen, modalOpen }) => {
  const [isMapImageLoaded, setIsMapImageLoaded] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);

  const [userNftsData, setUserNftsData] = useState<any[]>([]);

  const [selectedStakedTokens, setSelectedStakedTokens] = useState<number[]>([]);
  const [selectedUnstakedToken, setSelectedUnstakedToken] = useState<number | null>(null);

  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [stakedArray, setStakedArray] = useState<any[]>([]);

  const [showUnstakeBtn, setShowUnstakeBtn] = useState<boolean>(false);
  const [showClaimBtn, setShowClaimBtn] = useState<boolean>(false);
  const [showLvlUpBtn, setShowLvlUpBtn] = useState<boolean>(false);

  const [myTotalBalance, setMyTotalBalance] = useState<number>(0);

  const L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);

  const allTokensInitiated = useAppSelector((state) => state.TokensReducer.allTokensInitiated);
  const totalTokensCount = useAppSelector((state) => state.TokensReducer.totalTokensCount);
  const constantsError = useAppSelector((state) => state.ConstantsReducer.error);
  const ignoreTokensInitiating = useAppSelector(
    (state) => state.TokensReducer.ignoreTokensInitiating
  );
  const uninitiatedTokens = useAppSelector((state) => state.TokensReducer.uninitiatedTokens);

  const { MINIMUM_TO_EXIT, INJURY_TIME, REWARD_TIME } = useAppSelector((state) => state.ConstantsReducer);

  const walletAddress = useAppSelector((state) => state.AuthReducer.token);
  const provider = useProvider();

  const dispatch = useAppDispatch();

  const clearSelectedTokens = () => {
    setSelectedUnstakedToken(null);
    setSelectedStakedTokens([]);
  };

  const clearState = () => {
    clearSelectedTokens();
    setStakedArray([]);
    setUserNftsData([]);
    setShowUnstakeBtn(false);
    setShowClaimBtn(false);
    setShowLvlUpBtn(false);
  };

  const handleCheckShowButtons = () => {
    const currentUnixTime = new Date().getTime() / 1000;
    const canBeUnstaked = selectedStakedTokens.every((tokenId) => {
      const currentToken = stakedArray.find((nft) => {
        return nft.edition === tokenId;
      });
      return currentToken.unstakeTime < currentUnixTime;
    });
    const canBeClaimed = selectedStakedTokens.every((tokenId) => {
      const currentToken = stakedArray.find((nft) => {
        return nft.edition === tokenId;
      });
      return currentToken.claimTimeHence < currentUnixTime;
    });
    setShowClaimBtn(canBeClaimed);
    setShowUnstakeBtn(canBeUnstaked);
  };

  useLayoutEffect(() => {
    if (selectedStakedTokens.length > 0 && stakedArray.length > 0) {
      handleCheckShowButtons();
      const setCountdown: ReturnType<typeof setInterval> = setInterval(() => {
        handleCheckShowButtons();
      }, 1000);
      return () => clearInterval(setCountdown);
    }
  }, [selectedStakedTokens]);

  const getStakedTokens = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractGameLogic',
            contractAddress: contracts.addressGameLogic(L1),
            abi: contracts.abiGameLogic(L1),
            calls: [
              {
                reference: 'getStakedTokens',
                methodName: 'getStakedTokens',
                methodParameters: [walletAddress]
              }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);
        const stakedTokens =
          results.results.contractGameLogic.callsReturnContext[0].returnValues.map((returnValue) =>
            parseInt(returnValue.hex)
          );

        setTotalStaked(stakedTokens.length);

        if (stakedTokens.length > 0) {
          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'palace',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'palace',
                methodName: 'palace',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'fighters',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'fighters',
                methodName: 'fighters',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'levels',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: stakedTokens.map((stakedToken) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [stakedToken]
              }))
            },
            {
              reference: 'experience',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: stakedTokens.map((stakedToken) => ({
                reference: L1 ? 'experience' : 'exp',
                methodName: L1 ? 'experience' : 'exp',
                methodParameters: [stakedToken]
              }))
            }
          ];

          const stakeTokenInfo: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const inPalaceArrRes: {
            tokenID: number;
            claimTime: number;
            activityID: number;
            stakeTime: number;
          }[] = stakeTokenInfo.results.palace.callsReturnContext.map((returnContext) => {
            return {
              tokenID: returnContext.returnValues[L1 ? 0 : 1],
              claimTime: parseInt(returnContext.returnValues[L1 ? 1 : 2].hex, 16),
              activityID: returnContext.returnValues[L1 ? 2 : 0],
              stakeTime: parseInt(returnContext.returnValues[L1 ? 4 : 3].hex, 16)
            };
          });
          const inFightersArrRes: {
            tokenID: number;
            claimTime: number;
            activityID: number;
            stakeTime: number;
          }[] = stakeTokenInfo.results.fighters.callsReturnContext.map((returnContext) => {
            return {
              tokenID: returnContext.returnValues[L1 ? 0 : 1],
              claimTime: parseInt(returnContext.returnValues[L1 ? 1 : 2].hex, 16),
              activityID: returnContext.returnValues[L1 ? 2 : 0],
              stakeTime: parseInt(returnContext.returnValues[L1 ? 4 : 3].hex, 16)
            };
          });

          const levelsArrRes = stakeTokenInfo.results.levels.callsReturnContext.map(
            (returnContext) => {
              return returnContext.returnValues;
            }
          );
          const experienceArrRes = stakeTokenInfo.results.experience.callsReturnContext.map(
            (returnContext) => {
              return parseInt(returnContext.returnValues[0].hex, 16);
            }
          );
          const currentStakedTokens = [];

          for (const [index, stakedToken] of stakedTokens.entries()) {
            const tokenURI = IPFS_ROUTE + stakedToken + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            nftInfo.image = nftInfo.image.replace('ipfs://', 'https://ipfs.io/ipfs/');

            nftInfo.level = L1 ? levelsArrRes[index] : levelsArrRes[index].map(level => parseInt(level.hex, 16));
            nftInfo.experience = experienceArrRes[index];

            const inPalace = inPalaceArrRes.find((inPalace) => inPalace.tokenID === stakedToken);
            const inFighting = inFightersArrRes.find(
              (inFighting) => inFighting.tokenID === stakedToken
            );
            if (inPalace?.tokenID === stakedToken) {
              const { claimTime, stakeTime } = inPalace;
              const unstakeTime =
                (claimTime > stakeTime ? claimTime : stakeTime) + MINIMUM_TO_EXIT + MAIN_NET_DELAY;
              const claimTimeHence = claimTime + REWARD_TIME + MAIN_NET_DELAY;
              const newNftInfo = { ...nftInfo, claimTimeHence, unstakeTime };
              currentStakedTokens.push(newNftInfo);
            }
            if (inFighting?.tokenID === stakedToken) {
              const { claimTime, stakeTime } = inFighting;
              const unstakeTime =
                (claimTime > stakeTime ? claimTime : stakeTime) + MINIMUM_TO_EXIT + MAIN_NET_DELAY;
              const claimTimeHence = claimTime + REWARD_TIME + MAIN_NET_DELAY;
              const newNftInfo = { ...nftInfo, claimTimeHence, unstakeTime };
              currentStakedTokens.push(newNftInfo);
            }
          }
          setStakedArray(currentStakedTokens);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const getUserTokens: any = async () => {
    if (provider && walletAddress && !constantsError) {
      try {
        clearState();
        const web3 = new Web3(provider);

        const multicall = new Multicall({ web3Instance: web3, tryAggregate: true });

        const contractCallContext: ContractCallContext[] = [
          {
            reference: 'contractTown',
            contractAddress: contracts.addressYetiTown(L1),
            abi: contracts.abiYetiTown(L1),
            calls: [
              {
                reference: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
                methodName: L1 ? 'balanceOf' : 'viewTotalTokensOfOwner',
                methodParameters: [walletAddress]
              },]
          },
          {
            reference: 'contractFrxst',
            contractAddress: contracts.addressFrxst(L1),
            abi: contracts.abiFrxst(L1),
            calls: [
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [walletAddress] }
            ]
          }
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext);

        const yetisBalance = L1
          ? parseInt(results.results.contractTown.callsReturnContext[0].returnValues[0].hex, 16)
          : results.results.contractTown.callsReturnContext[0].returnValues.length;

        const myTotalBalance =
          parseInt(results.results.contractFrxst.callsReturnContext[0].returnValues[0].hex, 16) /
          BALANCE_MULTIPLIER;

        setMyTotalBalance(myTotalBalance);

        if (yetisBalance > 0) {
          const tokensIDs: number[] = [];
          if (L1) {
            const contractCallTokenIDs: ContractCallContext[] = [
              {
                reference: 'contractTown',
                contractAddress: contracts.addressYetiTown(L1),
                abi: contracts.abiYetiTown(L1),
                calls: Array.from({ length: yetisBalance }, (_, i) => ({
                  reference: 'tokenOfOwnerByIndex',
                  methodName: 'tokenOfOwnerByIndex',
                  methodParameters: [walletAddress, i]
                }))
              }
            ];

            const tokensIDsRes: ContractCallResults = await multicall.call(contractCallTokenIDs);
            tokensIDsRes.results.contractTown.callsReturnContext.forEach((returnContext) =>
              tokensIDs.push(parseInt(returnContext.returnValues[0].hex))
            );
          } else {
            results.results.contractTown.callsReturnContext[0].returnValues.forEach(
              (value) => tokensIDs.push(parseInt(value.hex))
            );
          }

          const gameTokensInfo: {
            edition: string;
            date: number;
            image: string;
            name: string;
            level?: number;
            experience?: number;
          }[] = [];
          for (const tokenID of tokensIDs) {
            if (uninitiatedTokens.includes(tokenID)) {
              continue;
            }
            const tokenURI = IPFS_ROUTE + tokenID + '.json';

            let res;
            try {
              res = await axios.get(tokenURI);
            } catch (e) {
              throw new Error('Something wrong with IPFS, network try again');
            }
            const nftInfo = res.data;
            gameTokensInfo.push(nftInfo);
          }

          const contractCallTokenInfo: ContractCallContext[] = [
            {
              reference: 'levels',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: 'levels',
                methodName: 'levels',
                methodParameters: [nftInfo.edition]
              }))
            },
            {
              reference: 'experience',
              contractAddress: contracts.addressGameLogic(L1),
              abi: contracts.abiGameLogic(L1),
              calls: gameTokensInfo.map((nftInfo) => ({
                reference: L1 ? 'experience' : 'exp',
                methodName: L1 ? 'experience' : 'exp',
                methodParameters: [nftInfo.edition]
              }))
            }
          ];

          const tokenInfoRes: ContractCallResults = await multicall.call(contractCallTokenInfo);
          const tokensLevels = tokenInfoRes.results.levels.callsReturnContext.map(
            (returnContext) => L1 ? returnContext.returnValues[0] : parseInt(returnContext.returnValues[0].hex)
          );
          const tokensExps = tokenInfoRes.results.experience.callsReturnContext.map(
            (returnContext) => parseInt(returnContext.returnValues[0].hex)
          );

          for (const [index, gameTokenInfo] of gameTokensInfo.entries()) {
            gameTokensInfo[index].image = gameTokenInfo.image.replace(
              'ipfs://',
              'https://ipfs.io/ipfs/'
            );
            gameTokensInfo[index].level = tokensLevels[index];
            gameTokensInfo[index].experience = tokensExps[index];
          }

          setUserNftsData(gameTokensInfo);
        }
      } catch (error) {
        handleShowErrorNotification(error);
      }
    }
  };

  const navigationUnstakedPrevRef = useRef(null);
  const navigationUnstakedNextRef = useRef(null);
  const navigationStakedPrevRef = useRef(null);
  const navigationStakedNextRef = useRef(null);

  const handleClaim = async () => {
    if (provider) {
      setFetching(true);
      try {
        const web3 = new Web3(provider);
        //@ts-ignore
        const contractGameLogic = await new web3.eth.Contract(contracts.abiGameLogic(L1), contracts.addressGameLogic(L1));
        if (L1) {
          await contractGameLogic.methods
            .claimMany(selectedStakedTokens, false)
            .send({ from: walletAddress });
        } else {
          const currentUnixTime = new Date().getTime() * 100;

          const nonce = Date.now();
          const signature = await transactionSignerClaim(walletAddress, nonce);
          await axios.post(SEND_SIGNED_API + "claimMany", {
            address: walletAddress,
            sign: signature,
            tokenIds: selectedStakedTokens,
            tokenId: 0,
            unStake: false,
            level: 0,
            exp: 0,
            rarity: 0,
            pass: nonce
          });

          let includes = true;
          while (includes) {
            await delay(1000);
            let claimTimeF = await contractGameLogic.methods.fighters(selectedStakedTokens[0]).call();
            let claimTimeS = await contractGameLogic.methods.palace(selectedStakedTokens[0]).call();

            claimTimeF = claimTimeF[2];
            claimTimeS = claimTimeS[2];
            const claimTimeHenceF = claimTimeF + REWARD_TIME + MAIN_NET_DELAY;
            const claimTimeHenceS = claimTimeS + REWARD_TIME + MAIN_NET_DELAY;
            includes = claimTimeHenceF < currentUnixTime && claimTimeHenceS < currentUnixTime;
          }
        }
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleUnStake = async () => {
    if (provider) {
      setFetching(true);
      const web3 = new Web3(provider);
      //@ts-ignore
      const contractGameLogic = await new web3.eth.Contract(contracts.abiGameLogic(L1), contracts.addressGameLogic(L1));
      try {
        if (L1) {
          await contractGameLogic.methods
            .claimMany(selectedStakedTokens, true)
            .send({ from: walletAddress });
        } else {
          const nonce = Date.now();
          const signature = await transactionSignerClaim(walletAddress, nonce);
          await axios.post(SEND_SIGNED_API + "claimMany", {
            address: walletAddress,
            sign: signature,
            tokenIds: selectedStakedTokens,
            tokenId: 0,
            unStake: true,
            level: 0,
            exp: 0,
            rarity: 0,
            pass: nonce
          });

          let includes = true;
          while (includes) {
            await delay(1000);
            includes = (
              await contractGameLogic.methods.getStakedTokens(walletAddress).call()
            ).includes(selectedStakedTokens[0].toString());
          }
        }
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        setFetching(false);
      }
      initRequests();
    }
  };

  const handleLevelUp = async () => {
    if (provider) {
      setFetching(true);
      try {
        const web3 = new Web3(provider);
        //@ts-ignore
        const contractGameLogic = await new web3.eth.Contract(contracts.abiGameLogic(L1), contracts.addressGameLogic(L1));
        if (L1) {
          setFetching(true);
          await contractGameLogic.methods
            .levelup(selectedUnstakedToken)
            .send({ from: walletAddress });
        } else {
          const lvlBefore = await contractGameLogic.methods.levels(selectedUnstakedToken).call();
          const nonce = Date.now();
          const signature = await transactionSigner(walletAddress, nonce);
          await axios.post(SEND_SIGNED_API + "levelup", {
            address: walletAddress,
            sign: signature,
            nonce: nonce,
            tokenIds: [selectedUnstakedToken],
          });

          // console.log(lvlBefore, await contractGameLogic.methods.levels(selectedUnstakedToken).call())
          let includes = true;
          while (includes) {
            await delay(1000);
            includes = (await contractGameLogic.methods.levels(selectedUnstakedToken).call()) == lvlBefore;
          };
        }
      } catch (error) {
        handleShowErrorNotification(error);
      } finally {
        initRequests();
        setFetching(false);
      }
      clearSelectedTokens();
    }
  };

  const initRequests = async () => {
    clearState();
    setFetching(true);
    try {
      const { unstakedTokensCount, stakedTokensCount } = await getTokensCount(
        provider,
        walletAddress,
        L1
      );
      const newTotalTokensCount = unstakedTokensCount + stakedTokensCount;
      if (totalTokensCount !== newTotalTokensCount) {
        if (L1) {
          const unInitiatedTokens = await getUninitiatedTokens(provider, walletAddress, L1);
          if (unInitiatedTokens.length > 0) {
            dispatch(setTokensInitiated(false));
            dispatch(setUninitiatedTokens(unInitiatedTokens));
          }
        }
      }
      await getUserTokens();
      await getStakedTokens();
    } catch (error) {
      handleShowErrorNotification(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (provider && walletAddress) {
      initRequests();
    }
  }, [
    provider,
    walletAddress,
    allTokensInitiated,
    ignoreTokensInitiating,
    MINIMUM_TO_EXIT,
    REWARD_TIME
  ]);
  const checkCouldNftLvlUp = (tokenId: number): boolean => {
    const selectedNft = userNftsData.find((nft: any) => nft.edition === tokenId);

    const level: number = selectedNft.level;

    if (!LEVELING_UP[level]) return false;
    if (LEVELING_UP[level].EXP > parseInt(selectedNft.experience, 10)) return false;
    if (LEVELING_UP[level].FRXST > myTotalBalance) return false;
    return true;
  };

  const handleSelectUnstakedToken = (tokenId: number) => {
    if (selectedUnstakedToken === tokenId) {
      setSelectedUnstakedToken(null);
    } else {
      setSelectedUnstakedToken(tokenId);
    }
    const canLvlUp = checkCouldNftLvlUp(tokenId);
    setShowLvlUpBtn(canLvlUp);
    setSelectedStakedTokens([]);
  };

  const handleSelectStakedToken = (tokenId: number) => {
    let updatedStakedTokens = [];
    if (selectedStakedTokens?.includes(tokenId)) {
      updatedStakedTokens = selectedStakedTokens.filter((token) => {
        return token !== tokenId;
      });
      setSelectedStakedTokens(updatedStakedTokens);
    } else {
      updatedStakedTokens = [...selectedStakedTokens, tokenId];
      setSelectedStakedTokens(updatedStakedTokens);
    }
    setSelectedUnstakedToken(null);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <>
      {fetching && <LoadingSplash />}
      {!fetching && (
        <div className={`modal-map-field`}>
          <div ref={ref} className="modal-map-field__content">
            <BackButton onCloseModal={onCloseModal} />
            <div className="modal-map-field__table">
              <div className="modal-map-table chest-table">
                <div className="modal-map-table__title">Your Collector’s Chest</div>
                <div className="modal-map-table__header">
                  <div className="modal-map-table__header-row">
                    <div>
                      total staked: <span>{totalStaked}</span>
                    </div>
                    <div>
                      $frxst balance: <span>{Math.round(myTotalBalance)}</span>
                    </div>
                  </div>
                  <div className="modal-map-table__header-row">
                    <div>
                      total unstaked: <span>{userNftsData.length}</span>
                    </div>
                  </div>
                </div>
                {stakedArray && stakedArray.length > 0 && (
                  <>
                    <div className="modal-map-table__row-title">
                      staked
                      {selectedStakedTokens.length > 0 &&
                        ` (${selectedStakedTokens.length} selected):`}
                    </div>
                    <div className="modal-map-table__content modal-map-field__row">
                      {stakedArray.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationStakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationStakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationStakedNextRef.current,
                          prevEl: navigationStakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {stakedArray?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  onClick={() => handleSelectStakedToken(nft.edition)}
                                  isSelected={selectedStakedTokens.includes(nft.edition)}
                                  nft={nft}
                                  showTimer={true}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                {userNftsData.length !== 0 && (
                  <>
                    <div className="modal-map-table__row-title">unstaked:</div>
                    <div
                      style={{ maxHeight: '20vw' }}
                      className={`modal-map-table__content modal-map-field__row`}
                    >
                      {userNftsData.length > 4 && (
                        <>
                          <button
                            className="button-prev"
                            type="button"
                            ref={navigationUnstakedPrevRef}
                          >
                            <img src={prevArrow} alt="" />
                          </button>
                          <button
                            className="button-next"
                            type="button"
                            ref={navigationUnstakedNextRef}
                          >
                            <img src={nextArrow} alt="" />
                          </button>
                        </>
                      )}
                      <Swiper
                        slidesPerView={4}
                        spaceBetween={0}
                        watchOverflow={true}
                        navigation={{
                          nextEl: navigationUnstakedNextRef.current,
                          prevEl: navigationUnstakedPrevRef.current
                        }}
                        updateOnImagesReady
                      >
                        {userNftsData?.map((nft: any) => {
                          return (
                            <SwiperSlide key={nft.edition}>
                              <div className="chest__row">
                                <NftItem
                                  onClick={() => handleSelectUnstakedToken(nft.edition)}
                                  isSelected={selectedUnstakedToken === nft.edition}
                                  nft={nft}
                                  showTimer={false}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  </>
                )}
                {userNftsData.length === 0 && stakedArray.length === 0 && (
                  <div className="text-center chest__empty-text">Your chest is empty</div>
                )}
                <div className="modal-map-table__buttons">
                  <button
                    className={`btn-outline ${selectedStakedTokens.length > 0 ? '' : 'invisible'
                      }`}
                    type="button"
                    onClick={handleClaim}
                    disabled={!showClaimBtn}
                  >
                    claim
                  </button>
                  <div></div>
                  <button
                    className={`btn-outline ${selectedUnstakedToken ? '' : 'invisible'
                      }`}
                    type="button"
                    onClick={handleLevelUp}
                    disabled={!showLvlUpBtn}
                  >
                    Level up
                  </button>
                  <div></div>
                  <button
                    className={`btn-outline ${selectedStakedTokens.length > 0 ? '' : 'invisible'
                      }`}
                    type="button"
                    onClick={handleUnStake}
                    disabled={!showUnstakeBtn}
                  >
                    claim & unstake
                  </button>
                </div>
              </div>
            </div>
            <img
              src={modalMap}
              alt=""
              className={`modal-map-field__img`}
              onLoad={() => setIsMapImageLoaded(true)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChestModal;
