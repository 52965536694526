import { createSlice } from '@reduxjs/toolkit';

interface TokensState {
  allTokensInitiated: boolean;
  gameAllowed: boolean;
  gameApproved: boolean;
  loading: boolean;
  stakedTokensCount: number;
  unstakedTokensCount: number;
  totalTokensCount: number;
  uninitiatedTokens: number[];
  ignoreTokensInitiating: boolean;
  l1ButtonSwich: boolean;
}

const initialState: TokensState = {
  allTokensInitiated: false,
  gameAllowed: false,
  gameApproved: false,
  loading: false,
  stakedTokensCount: 0,
  unstakedTokensCount: 0,
  totalTokensCount: 0,
  uninitiatedTokens: [],
  ignoreTokensInitiating: false,
  l1ButtonSwich: JSON.parse(localStorage.getItem('L1') || "true"),
};

export const TokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokensInitiated: (state, action) => {
      state.allTokensInitiated = action.payload;
      if (action.payload) {
        state.uninitiatedTokens = initialState.uninitiatedTokens;
      }
    },
    setUninitiatedTokens: (state, action) => {
      state.uninitiatedTokens =
        action.payload.length > 0
          ? [...state.uninitiatedTokens, ...action.payload]
          : action.payload;
    },
    setGameAllowed: (state, action) => {
      state.gameAllowed = action.payload;
    },
    setGameApproved: (state, action) => {
      state.gameApproved = action.payload;
    },
    setIgnoreTokensInitiating: (state, action) => {
      state.ignoreTokensInitiating = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setTokensCount: (state, { payload }) => {
      state.stakedTokensCount = payload.stakedTokensCount;
      state.unstakedTokensCount = payload.unstakedTokensCount;
      state.totalTokensCount = payload.stakedTokensCount + payload.unstakedTokensCount;
    },
    set11ButtonSwich:(state, action) => {
      state.l1ButtonSwich = action.payload;
      localStorage.setItem('L1', JSON.stringify(action.payload));
    }
  },
  extraReducers: {}
});

export const {
  setTokensInitiated,
  setGameAllowed,
  setGameApproved,
  setLoading,
  setTokensCount,
  setUninitiatedTokens,
  setIgnoreTokensInitiating,
  set11ButtonSwich
} = TokensSlice.actions;

export default TokensSlice.reducer;
