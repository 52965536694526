import { FC } from 'react';
import ModalContainer from '../../containers/modal';
import LoadingSplash from '../map/splashScreens/LoadingSplash';
import ConnectMetaMask from '../dialog/ConnectMetaMask';
import DisabledMetaMask from '../dialog/DisabledMetaMask';
import InitiateGame from '../dialog/InitiateGame';
import { useAppSelector } from '../../hooks/redux';
import { useProvider } from '../../hooks/provider';

interface IWithErrorCheckModals {
  modalOpen: boolean;
  onCloseModal: () => void;
  setModalOpen: (x:boolean) => void
}

const WithErrorCheckModals: FC<IWithErrorCheckModals> = ({ modalOpen, children, onCloseModal,setModalOpen }) => {
  const provider = useProvider();
  const L1 = useAppSelector((state) => state.TokensReducer.l1ButtonSwich);

  const { gameAllowed, gameApproved, allTokensInitiated, ignoreTokensInitiating, loading } =
    useAppSelector((state) => state.TokensReducer);
  const { token } = useAppSelector((state) => state.AuthReducer);

  return (
    <>
      {loading && <LoadingSplash />}
      <ModalContainer
        className={'dialog-modal-wrapper'}
        isOpen={modalOpen && !provider}
        closeModal={onCloseModal}
      >
        <DisabledMetaMask />
      </ModalContainer>
      <ModalContainer
        isOpen={modalOpen && !token && !!provider}
        className={'dialog-modal-wrapper'}
        closeModal={onCloseModal}
      >
        <ConnectMetaMask modalOpen={modalOpen} setModalOpen={setModalOpen} onCloseModal={onCloseModal} />
      </ModalContainer>
      <ModalContainer
        className={'dialog-modal-wrapper'}
        isOpen={
          modalOpen &&
          token &&
          provider &&
          (!gameAllowed ||
            !gameApproved ||
            (!allTokensInitiated && (!ignoreTokensInitiating || L1)))
        }
        closeModal={onCloseModal}
      >
        <InitiateGame modalOpen={modalOpen} setModalOpen={setModalOpen} onCloseModal={onCloseModal}/>
      </ModalContainer>
      <ModalContainer
        className={''}
        isOpen={
          modalOpen &&
          token &&
          provider &&
          gameApproved &&
          gameAllowed &&
          (allTokensInitiated || ignoreTokensInitiating || !L1)
        }
        closeModal={onCloseModal}
      >
        <>{children}</>
      </ModalContainer>
    </>
  );
};

export default WithErrorCheckModals;
