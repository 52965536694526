import { useEffect, FC, useState, ReactComponentElement, useRef } from 'react';
import modalMap from '../../assets/images/modal-map.png';
import { BackButton } from '../backButton';
import './index.scss';

interface IDialog {
  children: ReactComponentElement<any>;
  loading?: boolean;
  onCloseModal?:any;
  modalOpen?:any;
  setModalOpen?:any
}



const Dialog: FC<IDialog> = ({ children, loading,onCloseModal,modalOpen,setModalOpen }) => {

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any): void => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        setModalOpen(false);
      }
    };
  
    document.addEventListener('mousedown', checkIfClickedOutside);
  
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [modalOpen]);

  return (
    <div ref={ref} className={`dialog ${loading ? 'loading' : ''}`}>
      <BackButton onCloseModal={onCloseModal}/>
      <img src={modalMap} className="dialog__img" alt="" />
      {children}
    </div>
  );
};

export { Dialog };
