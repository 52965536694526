import { Store } from 'react-notifications-component';

interface MulticallError {
  code?: string | number;
  reason?: string;
  message?: string;
}

const AskUserToReloadApp = (message: any) => {
  // const result = confirm("Network error occured. Let's try to reload game?");
  AddErrorNotification({ title: "Network error occured", message });
  window.location.reload();
};

interface IAddErrorNotification {
  title: string | number;
  message: string | undefined;
}

const AddErrorNotification = ({ title, message }: IAddErrorNotification) => {
  if (!title || !message) return;
  Store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut']
  });
  setTimeout(() => {
    Store.removeAllNotifications();
  }, 5000);
};

const handleShowErrorNotification = (error: MulticallError | any) => {
  console.log(error);
  if (error?.code === 4001) {
    AddErrorNotification({ title: 'User denied transaction', message: 'Please allow contract' });
  } else if (error?.code === -32602) {
    AddErrorNotification({
      title: 'MetaMask is locked',
      message: 'Please, unlock your MetaMask wallet'
    });
  } else if (error?.code === -32000) {
    // AddErrorNotification({ title: 'Network error', message: error.message });
    AskUserToReloadApp(error.message);
  } else if (error?.code === -32603) {
    // AddErrorNotification({ title: 'Network error', message: error.message });
    AskUserToReloadApp(error.message);
  } else if (error?.code && error?.reason) {
    AddErrorNotification({ title: error.code, message: error.reason });
  } else if (error?.code && error?.message) {
    AddErrorNotification({ title: error.code, message: error.message });
  } else if (error?.name && error?.message) {
    // AddErrorNotification({ title: error.name, message: error.message });
    AskUserToReloadApp(error.message);
  } else if (error?.message) {
    AddErrorNotification({ title: error.message, message: error.message });
  }
};

export { handleShowErrorNotification };
